let clickable = document.querySelector('span.change-view');

if(clickable){
    let currentPage = "login";
    clickable.addEventListener('click',() => {
        if (currentPage === "login") {
            document.querySelector('.content-form').innerHTML =
                "  <form action=\""+base_url+"/user/createAccount\" method=\"POST\" class=\"sign-in-form\">\n" +
                "        <div class=\"form-container\">\n" +
                "            <div class=\"fields-container row align-items-center\">\n" +
                "                <label for=\"douane\" class=\"sign-in-form-input-label col-2\">Code douane*</label>\n" +
                "                <input type=\"text\" name=\"douane\" id=\"douane\" class=\"col-4\">\n" +
                "                <label for=\"email\" class=\"sign-in-input-label col-2\">E-mail*</label>\n" +
                "                <input type=\"email\" name=\"email\" id=\"email\" class=\"col-4\">\n" +
                "            </div>\n" +
                "            <div class=\"fields-container row align-items-center\">\n" +
                "                <label for=\"password\" class=\"sign-in-form-input-label col-2\">Mot de passe*</label>\n" +
                "                <input type=\"password\" name=\"pw\" id=\"pw\" class=\"col-4\">\n" +
                "                <label for=\"repeat-password\" class=\"sign-in-input-label col-2\">Répéter le mot de passe*</label>\n" +
                "                <input type=\"password\" name=\"r_pw\" id=\"r_pw\" class=\"col-4\">\n" +
                "            </div>\n" +
                "            <div class=\"fields-container row align-items-center\">\n" +
                "                <label for=\"surname\" class=\"sign-in-form-input-label col-2\">Nom*</label>\n" +
                "                <input type=\"text\" name=\"nom\" id=\"nom\" class=\"col-4\">\n" +
                "                <label for=\"name\" class=\"sign-in-input-label col-2\">Prénom*</label>\n" +
                "                <input type=\"text\" name=\"prenom\" id=\"prenom\" class=\"col-4\">\n" +
                "            </div>\n" +
                "            <div class=\"fields-container row align-items-center\">\n" +
                "                <label for=\"phone\" class=\"sign-in-form-input-label col-2\">Téléphone*</label>\n" +
                "                <input type=\"tel\" name=\"phone\" id=\"telephone\" class=\"col-4\">\n" +
                "                <label for=\"address\" class=\"sign-in-input-label col-2\">Adresse*</label>\n" +
                "                <input type=\"text\" name=\"adresse\" id=\"adresse\" class=\"col-4\">\n" +
                "            </div>\n" +
                "            <div class=\"fields-container row align-items-center\">\n" +
                "                <label for=\"cp\" class=\"sign-in-form-input-label col-2\">Code Postal*</label>\n" +
                "                <input type=\"text\" inputmode=\"numeric\" name=\"cp\" id=\"cp\" class=\"col-4\">\n" +
                "                <label for=\"ville\" class=\"sign-in-input-label col-2\">Ville*</label>\n" +
                "                <input type=\"text\" name=\"ville\" id=\"ville\" class=\"col-4\">\n" +
                "            </div>\n" +
                "            <div class=\"submit-container row justify-content-center\">\n" +
                "                <input class=\"submit-button\" type=\"submit\" value=\"S'inscrire\">\n" +
                "            </div>\n" +
                "        </div>\n" +
                "\n" +
                "    </form>";

            currentPage = "signin";
			clickable.innerHTML = "Vous avez un compte ? Connectez-vous >";
        } else {
            document.querySelector('.content-form').innerHTML =
                "<form action=\""+base_url+"/user/login\" method=\"POST\" class=\"login-form\">\n" +
				"  		 <input type=\"hidden\" class=\"form-control\" id=\"referer\" name=\"referer\" value=\"<?= $referer ?>\">\n" +
                "        <div class=\"form-container\">\n" +
                "            <div class=\"email-container row align-items-center\">\n" +
                "                <label for=\"email\" class=\"login-form-input-label col-2\">E-mail</label>\n" +
                "                <input type=\"email\" name=\"email\" id=\"email\" class=\"col-10\">\n" +
                "            </div>\n" +
                "            <div class=\"password-container row align-items-center\">\n" +
                "                <label for=\"pwd\" class=\"login-form-input-label col-2\">Mot de passe</label>\n" +
                "                <input type=\"password\" name=\"password\" id=\"pwd\" class=\"col-9\">\n" +
                "                <div id=\"hide-reveal-password\" class=\"hide-reveal-password col-1\">\n" +
                "                    <span class=\"iconify hide-reveal-password-icon\" data-icon=\"ion:eye-off\" style=\"color: white;\" data-flip=\"horizontal\"></span>\n" +
                "                </div>\n" +
                "            </div>\n" +
                "            <div class=\"forgotten-container row\">\n" +
                "                <a href=\""+base_url+"/user/forgot.html\" class=\"forgotten-password\">Mot de passe oublié ?</a>\n" +
                "            </div>\n" +
                "            <div class=\"submit-container row justify-content-center\">\n" +
                "                <input class=\"submit-button\" type=\"submit\" value=\"Se connecter\">\n" +
                "            </div>\n" +
                "        </div>\n" +
                "\n" +
                "    </form>";

            currentPage = "login";
			clickable.innerHTML = "Pas de compte ? Créez-en  un >";
			revealHidePassword();
        }
    });
}
