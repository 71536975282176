revealHidePassword = function() {
	let hideRevealButton = document.querySelector('#hide-reveal-password');
	if(hideRevealButton){
		hideRevealButton.addEventListener('click', () => {
			let field = document.querySelector('#pwd');
			console.log(field.type)
			if(field.type != "text"){
				document.querySelector('.hide-reveal-password-icon').setAttribute('data-icon', 'ion:eye');
				field.type = "text";
			} else {
				document.querySelector('.hide-reveal-password-icon').setAttribute('data-icon', 'ion:eye-off');
				field.type = "password";
			}
		});
	}
}
revealHidePassword();

