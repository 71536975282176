gestionInput = function () {
	let allInputs = document.querySelectorAll('.product-quantity-input');
	if(allInputs){
		allInputs.forEach(input => {
			let quantity = input.querySelector('.product-quantity');
			let addButton = input.querySelector('.product-quantity-add');
			let removeButton = input.querySelector('.product-quantity-remove');

			addButton.addEventListener("click", () => {
				quantity.value++;
			});

			removeButton.addEventListener("click", () =>  {
				if(quantity.value > 0) {
					quantity.value--;
				}
			});
		});
	}
}

gestionInput();



